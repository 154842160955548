<template>
    <div v-show="!isMobileMenuOpen" ref="noticeContainer" class="sim-notices">
        <NavbarNoticeDemoShop />
    </div>

    <header class="sim-navbar"
            :class="{
                'sim-navbar--mobile-opening': mobileMenuAnimation.opening,
                'sim-navbar--mobile-open': mobileMenuAnimation.open,
                'sim-navbar--mobile-closing': mobileMenuAnimation.closing,
                'sim-navbar--mobile-is-open': isMobileMenuOpen,
                'sim-navbar--minimal': type.startsWith('minimal'),
            }"
    >
        <!--  TOP PART  -->
        <div class="sim-navbar__top">
            <div class="sim-navbar__content sim-navbar__content--top">

                <div class="sim-navbar__search-area">
                    <!--  LOGO  -->
                    <NuxtLink :to="localePath('/')">
                        <span class="visually-hidden">
                            {{ $t('accessibility.logo_link') }}
                        </span>
                        <LogoComponent class="sim-logo" variant="header" />
                    </NuxtLink>
                    <!--  MAIN SEARCH  -->
                    <div v-if="properties.isSearchVisible" class="sim-navbar__search-wrapper">
                        <BaseUtilCollapse
                            v-model="isSearchOpen"
                            expanded-breakpoint="lg"
                            @after-enter="focusSearchInput"
                        >
                            <MainSearchComponent ref="mainSearchComponentRef" class="sim-navbar__search" />
                        </BaseUtilCollapse>
                    </div>
                </div>

                <div v-if="properties.isLoginAreaVisible.value" class="sim-navbar__login-area">
                    <!--  MAIN SEARCH on mobile  -->
                    <button v-if="properties.isSearchVisible"
                            class="sim-menu-btn sim-mobile-menu-btn lg:hidden"
                            type="button"
                            :aria-label="isSearchOpen ? $t('accessibility.close_search') : $t('accessibility.open_search')"
                            @click="handleToggleMobileSearch"
                    >
                        <IconSearch width="24" height="24" aria-hidden />
                    </button>

                    <CustomerAuthMenuBlock v-if="properties.isAuthBlockVisible" />
                    <!--  Needs to be a v-show because otherwise,
                          the cart drawer component would not play closing animation when unmounted  -->
                    <CustomerShortcuts v-show="properties.areShortcutsVisible" />
                    <LangSwitcherComponent v-if="properties.isLangSwitcherVisible" class="hidden lg:block" />

                    <!--  MOBILE MENU BUTTON  -->
                    <UiButtonMobileMenu
                        v-if="properties.isNavigationVisible"
                        ref="mobileMenuButton"
                        class="lg:hidden"
                        :menu-id="NAV_UL_ID"
                        :is-open="_isMobileMenuOpen"
                        @click="toggleMobileMenu"
                    />
                </div>

            </div>
        </div>

        <!-- this wrapper is necessary because `overflow: clip` doesn't work in Safari on iOS  -->
        <div v-if="properties.isNavigationVisible" class="sim-navbar__bottom-wrapper">

            <!--  BOTTOM PART  -->
            <div class="sim-navbar__bottom">
                <div class="sim-navbar__content sim-navbar__content--bottom">

                    <!--  PRIMARY NAVIGATION  -->
                    <!--  Intentionally not using the global ESC popup closing manager here for performance reasons
                      (the array of open popups would be updated every time the user hovers over a link)
                    -->
                    <nav ref="mobileMenuContent"
                         :aria-label="$t('accessibility.primary_navigation')"
                         class="sim-navbar__nav-el"
                         @keydown.esc="closeAllSubmenus"
                         @mouseleave="navMouseLeaveHandler"
                    >
                        <ul :id="NAV_UL_ID" class="sim-navbar__menu">
                            <NavbarMainLinkItem
                                v-for="(item, index) in navigation"
                                :key="index"
                                ref="mainLinkItemComponents"
                                :index="index"
                                :link="item"
                                :class="item.class"
                                :mobile-only="item.isMobileOnly"
                                :is-link-disabled="isLinkDisabled"
                                @opened="closeSubmenusExceptForIndex"
                            >

                                <template v-if="item.templateName === 'lang-switcher'" #leading>
                                    <span class="my-auto h-[18px] w-[25px]">
                                        <CoreImg
                                            class="size-full object-cover"
                                            :width="50"
                                            :src="localeProperties.flag"
                                            :alt="localeProperties.flagAlt"
                                            loading="lazy"
                                        />
                                    </span>
                                </template>
                            </NavbarMainLinkItem>
                        </ul>
                    </nav>

                </div>
            </div>
        </div>

        <BaseFullscreenBackground class="sim-navbar__fullscreen-overlay" :is-visible="isSomeSubmenuOpen && !isMobileWidth" />
    </header>
</template>

<script lang="ts" setup>
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import { MainSearchComponent, NavbarMainLinkItem, UiButtonMobileMenu } from '#components'
import { NavbarComponentSymbol } from '../../assets/ts/symbols/provide'
import type { ComputedRef } from 'vue'
import type { ComponentInstance } from '@core/types/utility'

defineOptions({
    inheritAttrs: false,
})

export type NavbarType = 'normal' | 'minimal' | 'minimal-with-lang'

const {
    type = 'normal',
} = defineProps<{
    type?: NavbarType
}>()

const NAV_UL_ID = 'sim-navbar-menu'

const properties = computed(() => {
    const defaultProperties: {
        isSearchVisible: boolean
        isAuthBlockVisible: boolean
        areShortcutsVisible: boolean
        isLangSwitcherVisible: boolean
        isNavigationVisible: boolean
        isLoginAreaVisible: ComputedRef<boolean>
    } = {
        isSearchVisible: true,
        isAuthBlockVisible: true,
        areShortcutsVisible: true,
        isLangSwitcherVisible: true,
        isNavigationVisible: true,
        isLoginAreaVisible: computed<boolean>(() => (
            defaultProperties.isSearchVisible ||
            defaultProperties.isAuthBlockVisible ||
            defaultProperties.areShortcutsVisible ||
            defaultProperties.isLangSwitcherVisible ||
            defaultProperties.isNavigationVisible
        )),
    }

    switch (type) {
        case 'minimal':
            defaultProperties.isSearchVisible = false
            defaultProperties.isAuthBlockVisible = false
            defaultProperties.areShortcutsVisible = false
            defaultProperties.isLangSwitcherVisible = false
            defaultProperties.isNavigationVisible = false
            break
        case 'minimal-with-lang':
            defaultProperties.isSearchVisible = false
            defaultProperties.isAuthBlockVisible = false
            defaultProperties.areShortcutsVisible = false
            defaultProperties.isNavigationVisible = false
            break
    }

    return defaultProperties
})

const { t, localeProperties } = useI18n()
const localePath = useLocalePath()

export interface NavigationLink {
    label: string
    url?: string | null
    ariaLabel?: string
    submenuHeading?: string
    subLinks?: NavigationSubLink[] | null
}

type NavigationItem = NavigationLink & {
    class?: string
    templateName?: string
    isMobileOnly?: boolean
}

interface NavigationSubLink {
    label: string
    url: string | null
    imgSrc: string | null
}

export type NavigationLinkWithoutSubLinks = Omit<NavigationLink, 'subLinks'>

const mainLinkItemComponents = ref<InstanceType<typeof NavbarMainLinkItem>[]>([])
const isSomeSubmenuOpen = computed(() => mainLinkItemComponents.value.some(component => component.isSubmenuOpen))

/**
 * Closes all submenus except for the one at the given index.
 * @param index The index of the main link whose submenu should not be closed. If -1, all submenus are closed.
 */
function closeSubmenusExceptForIndex(index: number) {
    // iterate over all the main links and close all the submenus except the one that was opened
    for (const [i, mainLinkItemComponent] of mainLinkItemComponents.value.entries()) {
        // skip the one that was opened just now
        if (i === index) continue

        mainLinkItemComponent.closeSubmenu()
    }
}

function closeAllSubmenus() {
    closeSubmenusExceptForIndex(-1)
}

function navMouseLeaveHandler() {
    // do not show a hover effect for the mobile menu
    if (isMobileMenuOpen.value) return

    closeAllSubmenus()
}

// ---------------------------------------------------------------------------------------------------------------------
// MOBILE MENU

const mainContentTopPadding = useStateMainContentTopPadding()
const noticeContainer = ref<HTMLElement | null>(null)

const isSearchOpen = ref<boolean>(false)
const _isMobileMenuOpen = ref<boolean>(false)
// whether the mobile menu is open when taking into account the animation state - ALMOST ALWAYS USE THIS
const isMobileMenuOpen = computed({
    get() {
        return _isMobileMenuOpen.value || mobileMenuAnimation.opening || mobileMenuAnimation.open || mobileMenuAnimation.closing
    },
    set(val) {
        if (val) {
            openMobileMenu()
        } else {
            closeMobileMenu()
        }
    },
})

const mobileMenuAnimation = reactive({
    opening: false,
    open: false,
    closing: false,
})

function toggleMobileMenu() {
    if (_isMobileMenuOpen.value) {
        closeMobileMenu()
    } else {
        openMobileMenu()
    }
}

const isLinkDisabled = ref<boolean>(false)

// Also update the values in NavbarMainLinkItem css class `.sim-navbar__sub-menu`
const OPEN_ANIMATION_DURATION = 300
const CLOSE_ANIMATION_DURATION = 200

const openAnimationDuration = computed(() => `${OPEN_ANIMATION_DURATION}ms`)
const closeAnimationDuration = computed(() => `${CLOSE_ANIMATION_DURATION}ms`)

let openingAnimationTimeout: NodeJS.Timeout
function openMobileMenu() {
    _isMobileMenuOpen.value = true
    isSearchOpen.value = false

    // disable link on mobile
    isLinkDisabled.value = true

    // add padding to the content to account for the notices disappearing when the menu is open
    mainContentTopPadding.value = noticeContainer.value?.offsetHeight ? `padding-top: ${noticeContainer.value.offsetHeight}px` : undefined

    // animate the mobile menu
    mobileMenuAnimation.closing = false
    if (!mobileMenuAnimation.open) {
        clearTimeout(openingAnimationTimeout)
        mobileMenuAnimation.open = true
        mobileMenuAnimation.opening = true
        openingAnimationTimeout = setTimeout(() => {
            mobileMenuAnimation.opening = false
        }, OPEN_ANIMATION_DURATION)
    }
}

let closingAnimationTimeout: NodeJS.Timeout
function closeMobileMenu(force: boolean = false) {
    _isMobileMenuOpen.value = false

    // re-enable link on desktop
    isLinkDisabled.value = false

    // animate the mobile menu
    mobileMenuAnimation.opening = false
    if (mobileMenuAnimation.open) {
        clearTimeout(closingAnimationTimeout)
        closeAllSubmenus()
        mobileMenuAnimation.closing = true
        mobileMenuAnimation.open = false

        const finishClosing = () => {
            mobileMenuAnimation.closing = false
            // remove the padding from the content
            mainContentTopPadding.value = undefined
        }

        if (force) {
            finishClosing()
        } else {
            closingAnimationTimeout = setTimeout(finishClosing, CLOSE_ANIMATION_DURATION)
        }
    }
}

// the element that was focused when the mobile menu was opened (the one that opened the mobile menu)
let prevActiveElement: HTMLElement | null = null
const mobileMenuContent = useTemplateRef<HTMLElement>('mobileMenuContent')

// focus trap library initialization
const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(mobileMenuContent, {
    escapeDeactivates: false,
    allowOutsideClick: true,
    setReturnFocus: () => {
        // return the element to set the focus to, or `false` (not to focus anything)
        return prevActiveElement ?? false
    },
    fallbackFocus: () => document.body,
})


const mobileMenuButton = ref<InstanceType<typeof UiButtonMobileMenu> | null>(null)
useManagePopupOpening(isMobileMenuOpen, {
    closeCallback: closeMobileMenu,
    closeOnClickOutside: mobileMenuContent,
    ignoreElements: [mobileMenuButton],
}, {
    onOpen: () => {
        // TODO: activateFocusTrap() after animation completes

        // save the previously focused element, before the modal was opened (the one that opened the mobile menu)
        prevActiveElement = document.activeElement as HTMLElement
    },
    onClose: () => {
        // deactivate the focus trap when the modal is closed
        deactivateFocusTrap()
    },
})

const isMobileWidth = useIsMobileWidth()
watch(isMobileWidth, (value) => {
    if (!value) {
        // close the mobile menu when the screen is no longer mobile
        closeMobileMenu(true)
    } else {
        // close all submenus when the screen is mobile
        // (prevents the submenus for being visible for a split second after opening the mobile menu
        // when the screen is resized from desktop to mobile while a submenu was open)
        closeAllSubmenus()
    }
})

const router = useRouter()
watch(router.currentRoute, () => {
    // close the mobile menu when the route changes
    closeMobileMenu()
    // close the search when the route changes
    isSearchOpen.value = false
})

// Search
const mainSearchComponentRef = ref<ComponentInstance<typeof MainSearchComponent> | null>(null)
function handleToggleMobileSearch() {
    isSearchOpen.value = !isSearchOpen.value
    closeMobileMenu()
}

function focusSearchInput() {
    mainSearchComponentRef.value?.focusInput()
}

// Locale Switcher
const availableLocales = useAvailableLocales()
const switchLocalePath = useSwitchLocalePath()

provide(NavbarComponentSymbol, {
    isMobileMenuOpen: isMobileMenuOpen,
})

// TODO: make react to locale change
const { data: menuCategoriesResponse } = await useCategoriesTreeApiService().useGetForMenu()

const navigation = computed<NavigationItem[]>(() => [
    ...((menuCategoriesResponse.value?.getItems() ?? []).map((category) => {
        return {
            label: category.name ?? '',
            url: category.urls,
            subLinks: category.children!.map(child => ({
                label: child.name ?? '',
                url: child.urls,
                imgSrc: child.thumbnailImageUrl!,   // TODO: add fallback image
            })),
        }
    })),
    {
        isMobileOnly: true,
        class: 'mt-auto pt-10',
        templateName: 'lang-switcher',
        label: localeProperties.value.code.toUpperCase(),
        submenuHeading: t('accessibility.switch_locale'),
        ariaLabel: t('accessibility.switch_locale'),
        subLinks: availableLocales.value.map(locale => ({
            label: locale.name!,
            url: switchLocalePath(locale.code),
            imgSrc: locale.flag!,
        })),
    },
])

</script>

<style lang="scss" scoped>
@use "sass:math";

$main-gap: 2.5rem;
$navbar-c-top: $sim-c-white;
$navbar-c-bottom: $sim-c-white;
$navbar-side-padding: 4.25rem;
$navbar-mobile-side-padding: math.div($navbar-side-padding, 4);

.sim-notices {
    z-index: $sim-z-index-navbar-notice;
}

.sim-logo {
    flex-shrink: 0;
    width: 5rem;

    @include more-than-custom(xs) {
        width: 6.25rem;
    }

    @include more-than(lg) {
        width: initial;
    }
}

.sim-navbar {
    position: sticky;
    top: 0;
    isolation: isolate;
    box-sizing: border-box;

    z-index: $sim-z-index-navbar;
    width: 100%;
}

.sim-navbar.sim-navbar--mobile-is-open {
    display: flex;
    flex-direction: column;
}

.sim-navbar__top {
    background-color: $navbar-c-top;
    border-bottom: 1px solid $sim-c-basic-400;
    height: $sim-navbar-mobile-top-height;
    flex-shrink: 0;
    z-index: 1;

    @include more-than(lg) {
        height: $sim-navbar-top-height
    }
}

// workaround for `overflow: clip` not working in Safari on iOS
.sim-navbar__bottom-wrapper {
    @include less-than(lg) {
        position: absolute;
        overflow-x: hidden;
    }
}

.sim-navbar__bottom {
    position: relative; // holds the submenu
    height: 3.25rem;

    background-color: $navbar-c-bottom;
    box-shadow: 0 1px 0 0 $sim-c-basic-400;  // instead of border-bottom (for seamless hover to submenu)
    box-sizing: border-box;

    @include less-than(lg) {
        position: absolute; // make the menu not affect the height of the header
        right: -100%;       // move the menu out of the screen
        transform: translateX(100%);
        flex-grow: 1;
    }
}

.sim-navbar--mobile-is-open .sim-navbar__bottom-wrapper {
    top: $sim-navbar-mobile-top-height;
    right: 0;
    height: calc(100vh - $sim-navbar-mobile-top-height);
    height: calc(100dvh - $sim-navbar-mobile-top-height);
    width: 100%;
}

.sim-navbar--mobile-is-open .sim-navbar__bottom {
    width: 100%;
    height: 100%;
    right: 0;
}

.sim-navbar--mobile-opening .sim-navbar__bottom {
    transition: transform v-bind(openAnimationDuration) $sim-navbar-animation-timing-in;
}

.sim-navbar--mobile-open .sim-navbar__bottom {
    transform: translateX(0);
}

.sim-navbar--mobile-closing .sim-navbar__bottom {
    transition: transform v-bind(closeAnimationDuration) $sim-navbar-animation-timing-out;
}

$navbar-content-desktop-padding: 0 safe-inset-right($navbar-side-padding) 0 safe-inset-left($navbar-side-padding);
$navbar-content-mobile-padding: 0 safe-inset-right($navbar-mobile-side-padding) 0 safe-inset-left($navbar-mobile-side-padding);
// needs to be deep to fall into <NavbarMainLinkItem />
:deep(.sim-navbar__content) {
    display: flex;
    padding: $navbar-content-mobile-padding;
    margin: 0 auto;

    height: 100%;
    max-width: $sim-content-width;
    box-sizing: border-box;

    @include more-than(lg) {
        padding: $navbar-content-desktop-padding;
    }
}

.sim-navbar__content--top {
    position: relative;
    justify-content: space-between;
    gap: $sim-navbar-item-spacing;

    @include more-than(lg) {
        gap: $main-gap;
    }
}

.sim-navbar__content--bottom {
    position: relative;
    align-items: center;
    padding: 0;

    @include more-than(lg) {
        position: initial;
        padding: $navbar-content-desktop-padding;
    }
}

.sim-navbar__nav-el {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: $navbar-content-mobile-padding;
    padding-top: 2 * $navbar-mobile-side-padding;

    overflow-y: auto;

    @include more-than(lg) {
        width: initial;
        padding: 0;
        overflow-y: initial;
    }
}

.sim-navbar__search-area,
.sim-navbar__login-area {
    display: flex;
    align-items: center;
}

.sim-navbar__search-area {
    gap: 5rem;
    flex-grow: 1;
}

.sim-navbar__search-wrapper {
    z-index: 1;

    @include less-than(lg) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY(100%);
        padding: $navbar-content-mobile-padding;
        background-color: $navbar-c-top;
        border-top: 1px solid $navbar-c-top;
        border-bottom: 1px solid $sim-c-basic-400;
    }

    @include more-than(lg) {
        width: 100%;
    }
}

.sim-navbar__search {
    @include less-than(lg) {
        padding-bottom: 1.125rem;
    }
}

.sim-navbar__login-area {
    gap: $sim-navbar-mobile-item-spacing;

    @include more-than(lg) {
        gap: $main-gap;
    }
}

.sim-navbar__menu {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    height: 100%;

    @include less-than(lg) {
        flex-direction: column;
    }
}

.sim-navbar__fullscreen-overlay {
    display: none;
    z-index: -2;

    @include more-than(lg) {
        display: initial;
    }
}


</style>
